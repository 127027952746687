const SET_MODAL_OPEN = state => {
  state.isOpen = true
}

const SET_MODAL_CLOSE = state => {
  state.isOpen = false
}

const SET_MODAL_ACTION = (state, payload) => {
  state.action = payload
}

const SET_CURRENT_MODAL = (state, payload) => {
  state.currentModal = payload
}

export default {
  SET_MODAL_OPEN,
  SET_MODAL_CLOSE,
  SET_MODAL_ACTION,
  SET_CURRENT_MODAL
}
