<template>
  <div v-if="item.child && item.child.length" class="c-nav-mobile-item">
    <a href="#" class="c-nav-mobile-item__link c-nav-mobile-item__link--nested" @click.prevent="openNestedNav">
      <span>
        {{ item.title }}
      </span>
      <span class="c-nav-mobile-item__link-icon">
        <NextIcon />
      </span>
    </a>
  </div>

  <div v-else class="c-nav-mobile-item">
    <div v-if="attributes">
      <router-link v-if="getComponent == 'router-link'" v-bind="attributes" v-text="item.title" @click.native="closeMenu"/>
      <a v-if="getComponent == 'a'" v-bind="attributes" v-text="item.title" @click="closeMenu"/>
    </div>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import NextIcon from '@/assets/svg/Next'

const attributesMap = (attribute, item) => {
  const map = { 
    a: {
      href: item.url,
      target: '_blank'
    },
    'router-link': {
      to: item.url
    }
  }

  return map[attribute]
}

export default {
  name: 'NavMobileItem',

  props: {
    item: Object
  },

  components: {
    NextIcon
  },

  setup(props, { emit }) {
    const getComponent = computed(() => {
      const hasExternalLink = props.item.url && /http?s:\/\//g.test(props.item.url)

      if (hasExternalLink) {
        return 'a'
      } else {
        return 'router-link'
      }
    })
    let attributes = ref(null)
    const openNestedNav = () => {
      emit('open-nested-nav', props.item)
    }
    const closeMenu = () => {
      emit('close-menu')
    }
    attributes = ref(attributesMap(getComponent.value, props.item))

    return {
      getComponent,
      attributes,
      openNestedNav,
      closeMenu
    }
  }
}
</script>

<style lang="scss">
.c-nav-mobile-item__link {
  &--nested {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
