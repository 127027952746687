const en = {
  LANGUAGE_CODE: 'en_GB',
  LANGUAGE_NAME: 'English',
  HOME_BUBBLE_TEXT: 'Jeg er Guru. Skriv inn det du trenger forklaring på.',
  NAV_GURU: 'Guru',
  NAV_BOOKS: 'Books',
  NAV_HELP: 'Help',
  NAV_TOOLS: 'Tools',
  NAV_HANDBOOK: 'Handbook',
  NAV_EXERCISEBOOK: 'Exercise Book',
  NAV_AUDIOBOOK: 'Listen to audiobook',
  NAV_TEACHER: 'Teacher',
  TOOLS_HEADING: 'Tools',
  TOOLS_TEXT: 'Fagbokforlaget tilbyr en rekke forskjellige verktøy som kan hjelpe deg i norskfaget.',
  LOGIN_LOGOUT: 'Logout',
  LOGIN_BUTTON: 'Login',
  LOGIN_HEADING: 'Velkommen til Synopsis',
  LOGIN_TEXT: 'Norsk for ungdomstrinnet<br> Logg inn i eportal for å få tilgang',
  SEARCH_PLACEHOLDER: 'What are you looking for? :)',
  CONCEPT_SEE_ALSO: 'See also',
  MODAL_LOGIN_TEXT: 'Du må logge inn for å få tilgang til lydboka',
  MODAL_LOGIN_CANCEL: 'Avbryt',
  MODAL_LOGIN_INFO: 'For å bruke lydboka må skolen din ha kjøpt tilgang',
  MODAL_LOGIN_INFO_LINK: 'Mer informasjon',
  MODAL_ACCESS_TEXT: `
  Du har ikke tilgang til lydboka<br>
  <a href="https://www.fagbokforlaget.no/Synopsis-Lesebok,-lydbok/I9788211035233" target="_blank" rel="nofollow noopener">Mer informasjon</a><br>
  eller<br>
  Ta kontakt med <a href="mailto:digitalt@fagbokforlaget.no">digitalt@fagbokforlaget.no</a>
  `,
  MENU: 'Show navigation',
  DOWNLOAD: 'Download'
}

export default en
