import actions from './actions'
import mutations from './mutations'

const state = {
  isOpen: false,
  action: null,
  currentModal: 'ModalLogin'
}

export default {
  state,
  actions,
  mutations,
  namespaced: true
}
