<template>
  <div v-show="isOpen" class="c-modal">
    <component :is="currentModal" />
  </div>
</template>

<script>
import ModalLogin from '@/components/ModalLogin'
import ModalNoAccess from '@/components/ModalNoAccess'

export default {
  name: 'Modal',

  components: {
    ModalLogin,
    ModalNoAccess
  },

  computed: {
    isOpen() {
      return this.$store.state.modal.isOpen
    },

    currentModal() {
      return this.$store.state.modal.currentModal
    }
  }
}
</script>

<style lang="scss">
.c-modal {
  position: fixed;
  max-width: $xlarge;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  right: 0;
  left: 0;
  border: 2px solid $color-brand;
  background: $color-white;
}
</style>