<template>
  <div class="c-modal-login">
    <div class="c-modal-login__content">
      <p>
        {{ $t('MODAL_LOGIN_TEXT' )}}
      </p>
      <FHButton class="c-modal-login__btn" @click.native="login">
        {{ $t('LOGIN_BUTTON') }}
      </FHButton>
      <FHButton class="c-modal-login__cancel" @click.native="closeModal">
        {{ $t('MODAL_LOGIN_CANCEL') }}
      </FHButton>
      <div class="c-modal-login__info">
        <p>
          {{ $t('MODAL_LOGIN_INFO') }}
        </p>
        <a href="https://www.fagbokforlaget.no/Synopsis-Lesebok,-lydbok/I9788211035233" target="_blank" rel="nofollow noopener">{{ $t('MODAL_LOGIN_INFO_LINK') }}</a>
      </div>
    </div>
    <div class="c-modal-login__img-wrapper">
      <img class="c-modal-login__img" src="/img/book-cover.png" alt="">
    </div>
  </div>
</template>

<script>
import FHButton from '@forlagshuset/v-button'
import auth from '@/auth'

export default {
  components: {
    FHButton
  },

  methods: {
    login() {
      auth.authorize({
        'redirectUrl': this.$store.state.modal.action.redirectUrl
      })
    },

    closeModal() {
      this.$store.dispatch('modal/closeModal')
    }
  }
}
</script>

<style lang="scss">
.c-modal-login {
  max-height: 100vh;
  height: 100%;
  padding: 0.5rem;
  overflow: auto;

  @include bp(medium) {
    display: flex;
  }
}

.c-modal-login__img-wrapper {
  display: none;

  @include bp(medium) {
    display: block;
    width: 30%;
  }
}

.c-modal-login__content {
  text-align: center;
  padding-top: 3rem;

  @include bp(medium) {
    width: 70%;
  }
}

.c-modal-login__img {
  width: 100%;
  display: block;
}

.c-modal-login__btn {
  margin-top: 1rem;
  background: $color-brand;
  border: 0;
  color: $color-white;
  font-weight: 700;
  padding: 0.75rem;
  font-size: 1.8rem;
  max-width: 40rem;
  border-radius: 10rem;
  width: 100%;
}

.c-modal-login__cancel {
  background: none;
  padding: 0;
  display: block;
  border: 0;
  margin: 2rem auto;
  font-weight: 700;
}

.c-modal-login__info {
  margin: 4rem 0;

  p {
    margin: 0;
  }

  a {
    color: $searched-concept-title;
  }
}
</style>