<template>
  <div class="c-text-element">
    <FHHeading header="h3" :text="item.title" />
    <FHHeading header="h4" :text="item.subtitle" />
    <div v-md-html="item.body" />
  </div>
</template>

<script>
import FHHeading from '@forlagshuset/v-heading'

export default {
  components: {
    FHHeading
  },

  props: {
    item: Object
  }
}
</script>

<style lang="scss">
.c-text-element {
  p {
    max-width: 670px;
  }
  table {
    margin: 3rem auto;
    border-radius: 4px;
    border: 3px solid $table-border;
    border-collapse: collapse;
  }

  th, td {
    padding: 1rem;
    border: 3px solid $table-border;
  }
}
</style>