<template>
  <div class="c-image-element" :class="{'c-image-element--first': index === 0 && countConceptImages > 1}">
    <picture class="c-image-element__picture" @click="imageClick">
      <img class="c-image-element__img"
        :src="src"
        :alt="item.image_type.altText" />
    </picture>
    <span class="c-image-element__caption">
      {{ item.image_type.caption }}
    </span>
    <div class="c-image-element__description"
      v-md-html="item.image_type.description" />
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { addCloudinaryUrlParams } from '@/utils'

export default {
  props: {
    item: Object,
    index: Number,
    countConceptImages: Number
  },

  setup (props, ctx) {
    const src = ref('')

    onMounted(() => {
      const coverURL = props.item.image_type.file.url
      const param = 'w_800,c_scale' // Should be adjusted per project need (styling overview list for example)
      src.value = addCloudinaryUrlParams(coverURL, param)
    })

    const imageClick = () => {
      ctx.emit('open-lightbox', {
        thumb: src.value,
        src: src.value,
        caption: props.item.image_type.caption || ''
      })
    }

    return {
      src,
      imageClick
    }
  }
}
</script>

<style lang="scss">
.c-image-element {
  &:not(.c-image-element--first) {
    width: 50%;
    float: right;
    padding: 0.5rem 0 0.5rem 0.5rem;

    img {
      width: 50%;
    }
  }
}

.c-image-element__picture {
  cursor: pointer;
}
</style>