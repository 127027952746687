import { fetchWithAuth } from '@/api'

// Shorthand
const fetchSH = async param => (await fetchWithAuth(param)).json()

const fetchCourseBySlug = async courseSlug => {
  const data = await fetchSH('courses/' + courseSlug)

  return data
}

const fetchCourseAllVideoType = courseSlug => fetchSH('courses/' + courseSlug + '/videotype')

const fetchCourseAllAudioType = courseSlug => fetchSH('courses/' + courseSlug + '/audiotype')

const fetchCourseAllArticleType = courseSlug => fetchSH('courses/' + courseSlug + '/articletype')

const fetchCourseAllConceptTypes = courseSlug => fetchSH('courses/' + courseSlug + '/concepttype')

const fetchCourseAllComponent = (courseSlug, componentName) => fetchSH('courses/' + courseSlug + '/component/' + componentName)

const fetchUnitBySlug = async unitSlug => {
  const data = await fetchSH('units/' + unitSlug)

  return data
}

const fetchUnitLessons = async lessonsIds => {
  const lessons = []
  let preserveOrder = lessonsIds

  await Promise.all(lessonsIds.map(async lesson => {
    lessons.push(await fetchSH('lessons/' + lesson))
  }))
  preserveOrder = preserveOrder.map(i => lessons.filter(l => l.id === i)[0])

  return preserveOrder
}

const fetchLesson = async lessonSlug => {
  const data = await fetchSH('lessons/' + lessonSlug)

  return data
}

const fetchLessonUnit = unitId => fetchSH('units/' + unitId)

export {
  fetchCourseBySlug,
  fetchUnitBySlug,
  fetchUnitLessons,
  fetchLesson,
  fetchLessonUnit,
  fetchCourseAllVideoType,
  fetchCourseAllAudioType,
  fetchCourseAllArticleType,
  fetchCourseAllConceptTypes,
  fetchCourseAllComponent
}
