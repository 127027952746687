<template>
  <div class="c-modal-no-access">
    <Hamburger @click.native="closeModal" :has-open-menu="true" class="c-modal-no-access__close" />
    <div class="c-modal-no-access__content">
      <p v-html="$t('MODAL_ACCESS_TEXT')" />
    </div>
  </div>
</template>

<script>
import Hamburger from '@/components/Hamburger'
import auth from '@/auth'

export default {
  components: {
    Hamburger
  },

  methods: {
    login() {
      auth.authorize({
        'redirectUrl': this.$store.state.modal.action.redirectUrl
      })
    },

    closeModal() {
      this.$store.dispatch('modal/closeModal')
    }
  }
}
</script>

<style lang="scss">
.c-modal-no-access {
  position: relative;
}
.c-modal-no-access__content {
  max-height: 100vh;
  height: 100%;
  padding: 3rem 0;
  overflow: auto;
  text-align: center;
  line-height: 2.5;

  p {
    margin: 0;
  }

  a {
    color: $searched-concept-title;
  }
}

.c-modal-no-access__close {
  display: block;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  left: auto;
  background: $color-brand;
  padding: 1.5rem;
  border-radius: 0.5rem;

  span {
    margin-top: 0.55rem;
    background: $color-white;
  }
}
</style>