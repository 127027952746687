<template>
  <button
    class="c-hamburger"
    :class="{'c-hamburger--open': open}"
    :aria-label="$t('MENU')"
    aria-controls="mobile-navigation"
    @click="toggle"
  >
    <span class="c-hamburger__slice" v-for="item in Array(3)" :key="item" />
  </button>
</template>

<script>
import { computed } from '@vue/composition-api'

export default {
  name: 'Hamburger',

  props: {
    hasOpenMenu: Boolean
  },

  setup(props, { emit }) {
    const open = computed(() => props.hasOpenMenu)
    const toggle = () => {
      emit('toggle-menu')
    }

    return {
      open,
      toggle
    }
  }
}
</script>

<style lang="scss">
.c-hamburger {
  @include btnReset();
  position: absolute;
  left: 1.5rem;
  top: 1.6rem;
  width: 35px;
  height: 32px;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;

  @include bp(large) {
    display: none;
  }
}

.c-hamburger__slice {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: $hamburger-slice;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;

  &:nth-child(1) {
    top: 0px;

    .c-hamburger--open & {
      top: 13px;
      transform: rotate(135deg);
    }
  }
  &:nth-child(2) {
    top: 13px;

    .c-hamburger--open & {
      opacity: 0;
      left: -60px;
    }
  }
  &:nth-child(3) {
    top: 26px;

    .c-hamburger--open & {
      top: 13px;
      transform: rotate(-135deg);
    }
  }
}
</style>
