import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home'
import Unit from '@/views/Unit'
import auth from '@/auth'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/unit/:slug',
    name: 'Unit',
    component: Unit
  },
  {
    path: '/skrivehjelp',
    name: 'Downloads',
    component: () => import('../views/pages/Downloads.vue')
  },
  {
    path: '/verktoy',
    name: 'Tools',
    component: () => import('../views/pages/Tools.vue')
  },

  {
    path: '/lesebok-lydbok',
    name: 'Lesebok Lydbok',
    component: () => import('../views/pages/Lydbok.vue'),
    meta: {
      requiresAuth: true,
      pid: process.env.VUE_APP_AUDIOBOOK_ID
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      hideHeader: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    auth.checkToken(to.query.token ? `?token=${to.query.token}` : '')
      .then(user => {
        store.dispatch('user/setToken')
        store.dispatch('user/setUser', user)

        auth.checkAccess(to.meta.pid)
          .then(res => {
            if (res.products.some(product => product.id === to.meta.pid)) {
              next()
            } else {
              store.dispatch('modal/setCurrentModal', 'ModalNoAccess')
              store.dispatch('modal/openModal')
              store.dispatch('modal/setModalAction', {
                redirectUrl: window.location.origin + to.path
              })

              next(from.path || '/')
            }
          })
      })
      .catch(() => {
        store.dispatch('modal/setCurrentModal', 'ModalLogin')
        store.dispatch('modal/openModal')
        store.dispatch('modal/setModalAction', {
          redirectUrl: window.location.origin + to.path
        })

        next(from.path || '/')
      })
  } else {
    next()
  }
})

export default router
