<template>
  <div class="c-concept-wrapper u-py">
    <FHHeading class="c-concept__title"
      header="h3" >
      <span class="c-concept__title-text"
        v-html="highlightPhrase(term, title)" />
    </FHHeading>
    <div v-md-html="highlightPhrase(term, description)" />
  </div>
</template>

<script>
import FHHeading from '@forlagshuset/v-heading'
import { highlightPhrase } from '@/utils'

export default {
  components: {
    FHHeading
  },

  props: {
    title: String,
    description: String,
    id: String,
    term: String
  },

  setup() {
    return {
      highlightPhrase
    }
  }
}
</script>

<style lang="scss">
.c-concept-wrapper {
  border-bottom: 1px solid $search-autocomplete-border;
  &:last-of-type {
    border: none;
  }
}
.c-concept__highlight {
  color: $search-autocomplete-title;
}
</style>