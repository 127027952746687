<template>
  <div id="app">
    <Topbar />
    <router-view/>
    <Footer />
    <Modal />
  </div>
</template>

<script>
import Topbar from '@/components/Topbar'
import Footer from '@/components/Footer'
import Modal from '@/components/Modal'
import auth from '@/auth'

export default {
  components: {
    Topbar,
    Footer,
    Modal
  },

  mounted() {
    auth.checkToken(this.$route.query.token ? `?token=${this.$route.query.token}` : '')
      .then(user => {
        this.$store.dispatch('user/setToken')
        this.$store.dispatch('user/setUser', user)
      })
      .catch(() => {})
  }
}
</script>

<style lang="scss">
html {
  line-height: 1.8;
}
#app {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  overflow-x: hidden;
  background: #FFFFFA;
  min-height: 100vh;

  @include bp-down(large) {
    padding-top: $mobile-top-padding;
  }
}
</style>
