<template>
  <div class="c-topbar" :class="{'c-topbar--only-logo': $route.meta.hideHeader, 'c-topbar--mobile-open': hasOpenMenu}">
    <div class="c-topbar__wrapper">
      <Hamburger :has-open-menu="hasOpenMenu" @toggle-menu="toggleMenu" />
      <router-link :to="{ name: 'Home' }"
        class="c-topbar__logo-link u-px">
        <img class="c-topbar__logo"
          alt="Synopsis logo"
          src="../assets/Synopsis_logo.png">
      </router-link>
      <NavMobile v-if="hasOpenMenu" @close-menu="toggleMenu()" />
      <Nav v-if="!$route.meta.hideHeader" />
      <LanguageSelect v-if="false" />
      <Login v-if="!$route.meta.hideHeader" />
    </div>
    <div v-if="hasOpenMenu" class="c-topbar__nav-mobile-underlay" @click="toggleMenu()" />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'

import Nav from '@/components/Nav'
import NavMobile from '@/components/NavMobile'
import LanguageSelect from '@/components/LanguageSelect'
import Login from '@/components/Login'
import Hamburger from '@/components/Hamburger'

export default {
  components: {
    Nav,
    LanguageSelect,
    Login,
    Hamburger,
    NavMobile
  },

  setup() {
    const hasOpenMenu = ref(false)
    const toggleMenu = () => {
      hasOpenMenu.value = !hasOpenMenu.value
    }

    return {
      hasOpenMenu,
      toggleMenu
    }
  }
}
</script>

<style lang="scss">
.c-topbar {
  @include bp-down(large) {
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 666;
  }
}

.c-topbar__wrapper {
  @include bp-down(large) {
    position: relative;
    z-index: 1;

    .c-topbar--mobile-open & {
      background: $mobile-menu-bg;
    }
  }
  @include bp(large) {
    display: flex;
    max-width: 100%;
    justify-content: space-between;

    .c-topbar--only-logo & {
      margin-top: 6rem;
    }
  }
}

.c-topbar__nav-mobile-underlay {
  @include bp-down(large) {
    position: fixed;
    background: rgba($nav-text, 0.4);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
}

.c-topbar__logo-link {
  display: inline-block;
  margin-top: 1.7rem;

  @include bp(large) {
    margin: auto 0 5rem;
  }
}

.c-topbar__logo {
  width: auto;
  height: 1.5rem;

  @include bp(large) {
    height: 2rem;
  }
}
</style>