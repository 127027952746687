<template>
  <div :class="['c-unit', `c-unit--${appSlug}`]">
    <!-- show audiobook -->
    <!-- or show downloads -->
    <ul class="c-unit__downloads u-px--3">
      <li v-for="item in downloads"
      :key="item.id"
      class="c-unit__download">
      <FHHeading header="h3" :text="item.file_type.name" />
      <div v-md-html="item.file_type.description" />
      <FHButton :href="item.file_type.file.url"
        class="c-unit__download-link">
        <Download />
      </FHButton>
      </li>
    </ul>
  </div>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { fetchUnitBySlug } from '@/services/strapi'
import { componentNameNormalize } from '@/utils'
import FHHeading from '@forlagshuset/v-heading'
import FHButton from '@forlagshuset/v-button'
import Download from '@/assets/svg/Download'

export default {
  components: {
    FHHeading,
    FHButton,
    Download
  },

  beforeRouteUpdate (to, from, next) {
    //update router view on the same component route
    if(to.name === from.name) {
      next()
      window.location.reload()
      return
    }
    return next()
  },

  setup(props, ctx) {
    const unit = ref({})
    const appSlug = computed(() => ctx.root.$options.router.history.current.params.slug)
    const downloads = ref([])
    const audiobook = ref([])

    onMounted(async () => {
      const response = await fetchUnitBySlug(appSlug.value)

      unit.value = response
      downloads.value = unit.value.content.filter(content => componentNameNormalize(content.__component) === 'ComponentLearningElementFileElement')
    })

    return {
      unit,
      appSlug,
      downloads,
      audiobook
    }
  },
}
</script>

<style lang="scss">
.c-unit__download {
  list-style: none none;
}
.c-unit__download {
  position: relative;
  min-height: 2rem;
}
.c-unit__download-link {
  border-radius: .5rem;
  padding: .5rem 1.5rem .25rem;
  background-color: $color-brand;
  position: absolute;
  right: 0;
  top: 0
}
</style>
