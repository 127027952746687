<template>
  <div class="c-language-select u-px u-py">
    <Dropdown
      class="c-language-select__dropdown u-px--2 u-px"
      :selectedIndex="locale"
      :options="languages"
      @item-selected="locale = $event" />
  </div>
</template>

<script>
import { ref, onMounted, watch } from '@vue/composition-api'
// import { ContentLoader } from 'vue-content-loader'
import Dropdown from '@forlagshuset/v-dropdown'

export default {
  name: 'LanguageSelect',

  components: {
    Dropdown
  },

  setup(props, { root }) {
    const languages = ref([])
    const locale = ref(0)

    onMounted(() => {
      let i = 0

      for (var lang in root.$i18n.messages) {
        languages.value.push({
          label: root.$i18n.messages[lang].LANGUAGE_NAME,
          value: root.$i18n.messages[lang].LANGUAGE_CODE
        })

        if(lang === root.$i18n.locale) {
          locale.value = i
        }
        i++
      }
    })

    watch(
      () => locale,
      (lang) => {
        console.log(lang)
        //save to vuex
    })

    return {
      languages,
      locale
    }
  }
}
</script>

<style lang="scss">
.c-language-select {
  display: flex;
  margin: auto 0rem 4rem auto;
}
.c-dropdown__label,
.c-dropdown__listbox {
  display: inline-flex;
}
.c-language-select__dropdown .c-dropdown__text {
  color: $language-select-label
}
</style>
