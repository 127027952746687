const openModal = ({commit}) => {
  commit('SET_MODAL_OPEN')
}

const closeModal = ({commit}) => {
  commit('SET_MODAL_CLOSE')
}

const setModalAction = ({commit}, action) => {
  commit('SET_MODAL_ACTION', action)
}

const setCurrentModal = ({commit}, payload) => {
  commit('SET_CURRENT_MODAL', payload)
}

export default {
  openModal,
  closeModal,
  setModalAction,
  setCurrentModal
}
