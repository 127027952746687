<template>
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0766 23.5675H5.55598C3.78391 23.5675 2.34609 22.1244 2.34609 20.3576V4.6424C2.34609 2.87033 3.78921 1.43251 5.55598 1.43251H12.1827C12.5806 1.43251 12.8989 1.11418 12.8989 0.716256C12.8989 0.318336 12.5806 0 12.1827 0H5.55598C2.99337 0 0.913574 2.0851 0.913574 4.6424V20.3576C0.913574 22.9202 2.99868 25 5.55598 25H12.0766C12.4745 25 12.7928 24.6817 12.7928 24.2837C12.7928 23.8858 12.4692 23.5675 12.0766 23.5675Z" :fill="fill"/>
    <path d="M23.876 11.996L19.3238 7.44381C19.0426 7.16261 18.5916 7.16261 18.3104 7.44381C18.0292 7.725 18.0292 8.17598 18.3104 8.45718L21.6423 11.7891H7.1103C6.71238 11.7891 6.39404 12.1074 6.39404 12.5054C6.39404 12.9033 6.71238 13.2216 7.1103 13.2216H21.6423L18.3104 16.5535C18.0292 16.8347 18.0292 17.2857 18.3104 17.5669C18.4484 17.7048 18.6341 17.7791 18.8145 17.7791C18.9948 17.7791 19.1805 17.7102 19.3185 17.5669L23.8707 13.0147C24.1572 12.7282 24.1572 12.2719 23.876 11.996Z" :fill="fill"/>
</svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: '#000'
    }
  }
}
</script>