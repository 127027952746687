<template>
  <FHFooter class="c-footer" hide-social />
</template>

<script>
import FHFooter from '@forlagshuset/v-small-footer'

export default {
  components: {
    FHFooter
  }
}
</script>

<style lang="scss">
.c-footer {
  background-color: $footer-bg;
  path {
    fill: $footer-icons;
  }

  .c-logo__svg path {
      fill: $color-black;
  }

  .c-contact-link__text {
    color: $color-black;
  }
}
.c-small-footer :focus {
  outline: 2px dashed $color-black;
}
</style>