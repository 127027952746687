const nn = {
  LANGUAGE_CODE: 'nn_NO',
  LANGUAGE_NAME: 'Nynorsk',
  HOME_BUBBLE_TEXT: 'Jeg er Guru. Skriv inn det du trenger forklaring på.',
  NAV_GURU: 'Guru',
  NAV_BOOKS: 'Bøker',
  NAV_HELP: 'Skrivehjelp',
  NAV_TOOLS: 'Verktøy',
  NAV_HANDBOOK: 'Håndbok',
  NAV_EXERCISEBOOK: 'Lesebok',
  NAV_AUDIOBOOK: 'Lesebok lydbok',
  NAV_TEACHER: 'Lærer',
  TOOLS_HEADING: 'Verktøy',
  TOOLS_TEXT: 'Fagbokforlaget tilbyr en rekke forskjellige verktøy som kan hjelpe deg i norskfaget.',
  LOGIN_LOGOUT: 'Logg ut',
  LOGIN_BUTTON: 'Logg Inn',
  LOGIN_HEADING: 'Velkommen til Synopsis',
  LOGIN_TEXT: 'Norsk for ungdomstrinnet<br> Logg inn i eportal for å få tilgang',
  SEARCH_PLACEHOLDER: 'What are you looking for? :)',
  CONCEPT_SEE_ALSO: 'Se også',
  MODAL_LOGIN_TEXT: 'Du må logge inn for å få tilgang til lydboka',
  MODAL_LOGIN_CANCEL: 'Avbryt',
  MODAL_LOGIN_INFO: 'For å bruke lydboka må skolen din ha kjøpt tilgang',
  MODAL_LOGIN_INFO_LINK: 'Mer informasjon',
  MODAL_ACCESS_TEXT: `
  Du har ikke tilgang til lydboka<br>
  <a href="https://www.fagbokforlaget.no/Synopsis-Lesebok,-lydbok/I9788211035233" target="_blank" rel="nofollow noopener">Mer informasjon</a><br>
  eller<br>
  Ta kontakt med <a href="mailto:digitalt@fagbokforlaget.no">digitalt@fagbokforlaget.no</a>
  `,
  MENU: 'Vis navigasjon',
  DOWNLOAD: 'Last ned'
}

export default nn
