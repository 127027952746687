<template>
  <div class="c-search-autocomplete">
    <i>{{ concepts.length }} treff på «{{ term }}»</i>
    <SearchConcept v-for="item in concepts"
      :key="item.id"
      :title="item.name"
      :description="getDescription(item) | truncate(50)"
      :id="item.id"
      :term="term"
      class="c-search-autocomplete__concept"
      @click.native="$emit('concept-click', item)"
      @keydown.enter.native="$emit('concept-click', item)"
      tabindex="0"
    />
  </div>
</template>

<script>
import SearchConcept from '@/components/SearchConcept'

export default {
  components: {
    SearchConcept
  },

  props: {
    term: String,
    concepts: Array
  },

  methods: {
    getDescription(item) {
      let firstBody = ''
      item.content.forEach(content => {
        if (firstBody) return
        if (content.body) {
          firstBody = content.body
        }
      })

      return firstBody
    }
  }
}
</script>

<style lang="scss">
.c-search-autocomplete {
  background: $search-autocomplete-bg;
  border-radius: 0 0 .5rem .5rem;
  max-height: 25rem;
  overflow-y: auto;
}
.c-search-autocomplete__concept {
  cursor: pointer
}
</style>