import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
import vClickOutside from 'v-click-outside'
import VueGtm from '@gtm-support/vue2-gtm';
import 'whatwg-fetch'
import { mdHtml } from '@/directives'
import 'normalize.css'
import App from './App.vue'
import './registerServiceWorker'
import { truncate } from '@/filters'
import router from './router'
import store from './store'
import i18n from '@/i18n'
import '@/assets/scss/main.scss'

Vue.config.productionTip = false

Vue.use(VueGtm, {
  id: 'GTM-NZ75DPN'
})

Vue.use(require('vue-shortkey'))
Vue.use(VueCompositionApi)
Vue.use(vClickOutside)
Vue.directive('md-html', mdHtml)
Vue.filter('truncate', truncate)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
