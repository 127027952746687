<template>
  <ul class="c-nav">
    <li class="c-nav__item" @click="isOpened = false">
      <router-link
        :to="{ name: 'Home'}"
        class="c-nav__link"
        @click.native="$store.dispatch('modal/closeModal')"
      >
        {{ $t('NAV_GURU') }}
      </router-link>
    </li>
    <li class="c-nav__item">
      <a href="#" @click.prevent="isOpened = !isOpened"
        class="c-nav__link">
        {{ $t('NAV_BOOKS') }}
        <ArrowDown
          class="c-nav__item-arrow"
          :class="{'c-nav__item-arrow--rotate': isOpened}"
        />
      </a>
      <ul v-if="isOpened" class="c-nav__subnav">
        <li @click="isOpened = !isOpened">
          <a href="https://reader.dbok.no/#/book/5e81ae9634c6c80019166c49/1"
            target="_blank"
            class="c-nav__link">
            {{ $t('NAV_HANDBOOK') }}
          </a>
        </li>
        <li @click="isOpened = !isOpened">
          <a href="https://reader.dbok.no/#/book/5e8450a234c6c8001916b3ae/1"
            target="_blank"
            class="c-nav__link">
            {{ $t('NAV_EXERCISEBOOK') }}
          </a>
        </li>
        <li @click="isOpened = !isOpened">
          <router-link to="lesebok-lydbok" class="c-nav__link">
            {{ $t('NAV_AUDIOBOOK') }}
          </router-link>
        </li>
      </ul>
    </li>
    <li class="c-nav__item" @click="isOpened = false">
      <router-link
        :to="{ name: 'Downloads' }"
        class="c-nav__link"
        @click.native="$store.dispatch('modal/closeModal')"
      >
        {{ $t('NAV_HELP') }}
      </router-link>
    </li>
    <li class="c-nav__item" @click="isOpened = false">
      <router-link
        :to="{ name: 'Tools' }"
        class="c-nav__link"
        @click.native="$store.dispatch('modal/closeModal')"
      >
        {{ $t('NAV_TOOLS') }}
      </router-link>
    </li>
    <li class="c-nav__item" @click="isOpened = false">
      <a
        href="https://www.synopsis.undervisning.fagbokforlaget.no"
        class="c-nav__link"
        target="_blank"
        rel="noopener noreferrer"
      >
        {{ $t('NAV_TEACHER') }}
      </a>
    </li>
  </ul>
</template>

<script>
import ArrowDown from '@/assets/svg/ArrowDown'

export default {
  components: {
    ArrowDown
  },
  data() {
    return {
      isOpened: false
    }
  }
}
</script>

<style lang="scss">
.c-nav {
  display: none;
  list-style: none none;
  margin: auto 0 5rem;

  @include bp(large) {
    display: flex;
  }

}
.c-nav__item-arrow {
  width: 0.8rem;

  &--rotate {
    transform: rotate(180deg);
  }
}
.c-nav__item {
  margin: 0 1rem;
  position: relative;
}
.c-nav__link {
  text-decoration: none;
  color: $nav-text;
  padding: .5rem 1.5rem;
  cursor: pointer;
  white-space: nowrap;

  &.router-link-exact-active {
    text-decoration: underline;
  }
}
.c-nav__subnav {
  position: absolute;
  left: 0;
  top: 2.1rem;
  width: 20rem;
  list-style: none none;
  padding: 0;
  background: $color-white;
  z-index: 1;

  li {
    margin-bottom: 1rem;
  }

  .c-nav__link {
    padding: .25rem 1.5rem;
  }
}
</style>