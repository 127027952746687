import showdown from 'showdown'

const markdownToHtml = md => {
  const converter = new showdown.Converter()
  converter.setOption('tables', true)

  return converter.makeHtml(md)
}

const addCloudinaryUrlParams = (url, param) => {
  let newURL
  const splitURL = url.split('upload/')

  newURL = `${splitURL[0]}upload/${param}/${splitURL[1]}`

  return newURL
}

const componentNameNormalize = name => {
  if (name.indexOf('-') === -1 || name.indexOf('.') === -1) return name

  const splitDots = name.split('.')
  const removedDots = splitDots.map(string => {
    return string[0].toUpperCase() + string.slice(1)
  }).join('')
  const splitHyphens = removedDots.split('-')
  const removedHyphensAndDots = splitHyphens.map(string => {
    return string[0].toUpperCase() + string.slice(1)
  }).join('')
  const modify = `Component${removedHyphensAndDots}`

  return modify
}

const highlightPhrase = (phrase, sentence) => {
  phrase = phrase.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  const re = new RegExp(phrase, 'gi')

  return sentence.replace(re, '<span class="c-concept__highlight">$&</span>')
}

export {
  markdownToHtml,
  addCloudinaryUrlParams,
  componentNameNormalize,
  highlightPhrase
}