<template>
  <div class="c-login u-mt">
    <TheButton
      v-if="$store.getters['user/hasUser']"
      class="c-login__button"
      @click.native="logout"
    >
      <Logout class="c-login__icon u-mr" />
      {{ $t('LOGIN_LOGOUT') }}
    </TheButton>
    <HeaderImage class="c-login__image" />
  </div>
</template>

<script>
import Logout from '@/assets/svg/Logout'
import HeaderImage from '@/assets/svg/HeaderImage'
import TheButton from '@forlagshuset/v-button'

export default {
  components: {
    Logout,
    HeaderImage,
    TheButton
  },

  methods: {
    logout() {
      this.$root.$store.dispatch('user/logoutUser')
    }
  }
}
</script>

<style lang="scss">
.c-login {
  display: none;
  margin-left: auto;
  text-align: center;

  @include bp(large) {
    display: block;
  }
}
.c-login__icon {
  vertical-align: middle;
}
.c-login__image {
  display: block;
  clear: both;
  width: 100%;
}
.c-login__button {
  padding: 0;
  background: none;
  border: 0;
}
</style>