<template>
  <div class="c-home o-wrapper">
    <div class="c-home__guru-wrapper">
      <picture class="c-home__col c-home__col--guru" @click="clearTerm()">
        <img class="c-home__guru-img" src="../assets/guru.png" />
      </picture>
      <div class="c-home__col c-home__col--2 u-px--3">
        <span class="c-home__bubble u-px--3">
          {{ $t('HOME_BUBBLE_TEXT') }}
        </span>
        <div class="c-home__search">
          <SearchBox
            ref="searchBox"
            class="c-home__searchbox u-px"
            :searchPlaceholder="$t('SEARCH_PLACEHOLDER')"
            autocomplete="on"
            :showResultsWhileTyping="showResultsWhileTyping"
            @submit-search="onTermSubmit($event)"
            :characters="minCharacters"
            :searchButton="hasSearchButton ? 'Søk' : ''"
          />
          <SearchAutocomplete v-if="term.length >= minCharacters && openAutocomplete"
            class="c-home__search-autocomplete u-pt--2 u-pb u-px"
            :term="term"
            :concepts="filteredConcepts"
            @concept-click="showSingleConcept($event)" />
        </div>
      </div>
    </div>

    <div v-if="singleConcept !== null"
      class="c-home__concept-container u-mx--3 u-px--3 u-mt">
      <FHHeading class="c-home__concept-title u-mt"
        header="h2"
        :text="singleConcept.name"></FHHeading>
      <component v-for="(section, i) in singleConcept.content"
        :key="section.id"
        :is="componentNameNormalize(section.__component)"
        :index="i"
        :count-concept-images="countConceptImages"
        :item="section"
        @open-lightbox="openLightbox"
      />
      <div v-if="relatedConcepts.length"
        class="c-home__concept-related">
        <FHHeading class="c-home__concept-title"
          header="h3"
          :text="$t('CONCEPT_SEE_ALSO')"></FHHeading>
        <FHButton v-for="item in relatedConcepts"
          :key="item.id"
          @click.native="showSingleConcept(item)"
          class="c-home__concept-related-button u-mr u-mb">
          {{ item.name }}
        </FHButton>
      </div>
    </div>
    <VueEasyLightbox :imgs="images" :toolbar="false" :visible="showLightBox" :show-thumbnails="false" @hide="onLightboxClose"></VueEasyLightbox>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from '@vue/composition-api'
import { fetchWithAuth } from '@/api'
import { fetchCourseBySlug } from '@/services/strapi'
import { componentNameNormalize } from '@/utils'
import VueEasyLightbox from 'vue-easy-lightbox'

import FHHeading from '@forlagshuset/v-heading'
import FHButton from '@forlagshuset/v-button'
import SearchBox from  '@forlagshuset/v-searchbox'
import SearchAutocomplete from  '@/components/SearchAutocomplete'
import ComponentLearningElementTextElement from '@/components/TextElement'
import ComponentLearningElementTextBox from '@/components/TextBox'
import ComponentLearningElementImageElement from '@/components/ImageElement'

const lunr = require('lunr')
require("lunr-languages/lunr.stemmer.support")(lunr)
require("lunr-languages/lunr.no")(lunr)

export default {
  name: 'Home',

  components: {
    FHHeading,
    FHButton,
    SearchBox,
    SearchAutocomplete,
    ComponentLearningElementTextElement,
    ComponentLearningElementTextBox,
    ComponentLearningElementImageElement,
    VueEasyLightbox
  },

  setup (props, ctx) {
    const appSlug = 'synopsis'
    const term = ref('')
    const allConcepts = ref([])
    const filteredConcepts = ref([])
    const singleConcept = ref({})
    const openAutocomplete = ref(false)
    const minCharacters = ref(2)
    const relatedConcepts = ref([])
    const hasSearchButton = ref(false)
    const showLightBox = ref(false)
    const countConceptImages = ref(0)
    const images = ref([])
    const showResultsWhileTyping = ref('on')
    const setSearchButton = () => {
      if (!window.matchMedia('(min-width: 768px)').matches) {
        hasSearchButton.value = true
        showResultsWhileTyping.value = 'off'
      } else {
        hasSearchButton.value = false
        showResultsWhileTyping.value = 'on'
      }
    }
    let lunrIdx = () => {}

    setSearchButton()

    onMounted(async () => {
      const course = await fetchCourseBySlug(appSlug)

      allConcepts.value = course.concepts.sort(function(a, b){
        if(a.name < b.name) { return -1; }
        if(a.name > b.name) { return 1; }
        return 0;
      })

      if (ctx.root.$route.query.concept) {
        showSingleConcept(allConcepts.value.filter(concept => ctx.root.$route.query.concept === concept.slug)[0])
      }

      const searchableText = () => allConcepts.value.map(concept => {
        const arrayOfBodies = []

        if (concept.content.length) {
          concept.content.forEach(content => {
            if (content.body) {
              arrayOfBodies.push(content.body)
            }
          })
        }

        concept.searchableContent = arrayOfBodies.join(' ')

        return concept
      })

      lunrIdx = lunr(function() {
        this.use(lunr.no)
        this.ref('id')
        this.field('name', { boost: 100 })
        this.field('searchableContent')

        searchableText().forEach(st => {
          this.add(st)
        })
      })

      window.addEventListener('resize', setSearchButton)
      document.querySelector('.c-topbar__logo-link').addEventListener('click', clearTerm)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', setSearchButton)
      document.querySelector('.c-topbar__logo-link').removeEventListener('click', clearTerm)
    })

    function onTermSubmit (e) {
      term.value = e
      openAutocomplete.value = true
      filteredConcepts.value = lunrIdx.search(term.value + '*').map(obj => {
        return allConcepts.value.filter(concept => obj.ref === concept.id)[0]
      })
    }

    function showSingleConcept (e) {
      const concept = e
      countConceptImages.value = 0
      relatedConcepts.value = []
      concept.concepts = []
      concept.related.forEach(async item => {
        const relatedId = typeof item === 'object' ? item.id : item
        relatedConcepts.value.push(await (await fetchWithAuth('concept-types/' + relatedId)).json())
      });
      openAutocomplete.value = false
      singleConcept.value = concept
      singleConcept.value.content.forEach(sc => {
        if (sc.__component === 'learning-element.image-element') {
          countConceptImages.value = countConceptImages.value + 1
        }
      })
      if (ctx.root.$route.query.concept && ctx.root.$route.query.concept === concept.slug) return
      ctx.root.$router.replace({ query: {concept: concept.slug} })
    }

    const clearTerm = () => {
      ctx.refs.searchBox.clearSearch()
      term.value = ''
      document.querySelector('.c-home__searchbox input').focus()
      singleConcept.value = {}
      relatedConcepts.value = []
      ctx.root.$router.replace({ query: {} })
    }

    const openLightbox = e => {
      showLightBox.value = true
      images.value = e.src
    }

    const onLightboxClose = () => {
      showLightBox.value = false
      images.value = ''
    }

    return {
      countConceptImages,
      showLightBox,
      onLightboxClose,
      openLightbox,
      term,
      clearTerm,
      allConcepts,
      filteredConcepts,
      onTermSubmit,
      showSingleConcept,
      singleConcept,
      componentNameNormalize,
      openAutocomplete,
      minCharacters,
      relatedConcepts,
      hasSearchButton,
      showResultsWhileTyping,
      images
    }
  }
}
</script>

<style lang="scss">
.c-home {
  min-height: calc(100vh - 21rem);
}
.c-home__guru-wrapper {
  @include bp(medium) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
}
.c-home__guru-img {
  width: 100%;
  height: auto;
  max-width: 444px;
  @include bp-down(large) {
      max-width: 60vw;
  }
}
.c-home__bubble {
  position: relative;
  background: $guru-bubble-bg;
  display: none;
  border-radius: .25rem;
  color: $gure-bubble-text;
  padding: 2rem;
  margin: 3rem 0;

  @include bp-down(medium) {
    background: transparent;
    padding: 0;
    display: block;
    text-align: center;
  }

  @include bp(xsmall) {
    margin: 1rem 0;
  }

  @include bp(medium) {
      display: block;
  }

  &:before {
    @include bp(medium) {
      content: '';
    }
    position: absolute;
    left: -4rem;
    top: 50%;
    width: 0;
    height: 0;
    border-top: .1rem solid transparent;
    border-bottom: 1rem solid transparent;
    border-right: 4rem solid $guru-bubble-bg;
  }
  strong {
    color: $guru-bubble-text-bold
  }
}
.c-home__search {
  position: relative;

    @include bp-down(medium) {
      margin: 0;
      padding: 0;
      display: block;
      width: 100%;
      text-align: center;
    }
}
.c-home__search-autocomplete {
  @include bp-down(medium) {
    margin-top: 1rem;
  }
}
.c-home__searchbox {
  @include bp-down(medium) {
    display: block;
  }
  
  .c-searchbox__textfield {
    @include bp-down(medium) {
      display: inline-block;
      width: 100%;
    }
  }

  @include bp(medium) {
    border: 5px solid $searchbox-border;
    border-radius: 0.25rem;
  }

  &::placeholder {
    color: $searchbox-placeholder;
  }
  .c-searchbox__input {
    background: $search-autocomplete-bg;
    border-radius: 0.5rem;
    border: none;

    @include bp-down(medium) {
      box-sizing: border-box;
      margin: 1.25rem 0;
      width: 100%;
      padding: .3rem 1rem;
    }

    @include bp(medium) {
      background: transparent;
      width: calc(100% - 2rem);
    }

    color: $searchbox-text;
    &:focus {
      border: none;
      outline: none;
    }
  }

  @include bp-down(medium) {
    .c-searchbox__cross-button {
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .c-searchbox__button {
    border-radius: 0.5rem;
    background: $color-mint;
    padding: 0 2rem;
    width: 100%;
    height: 2.6rem;
    padding: .3rem 1rem;
    font-weight: 700;
    color: $color-white;
  }
}
.c-home__col {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;

  &--2 {
    flex: 2;
    padding: 3rem 0;
    @include bp-down(medium) {
      padding: 0 2rem;
    }
  }

  &--guru {
    display: block;
    text-align: center;
    cursor: pointer;
    @include bp(medium) {
      text-align: right;
    }
  }
}
.c-home__concept-container {
  display: block;
  padding: 1rem;
  &::after {
    content: '';
    display: block;
    clear: both;
  }
}
.c-home__concept-title {
  color: $searched-concept-title;
}
.c-home__concept-container {
  .c-text-box {
    @include bp(medium) {
      width: 50vw;
    }
    margin: 0 auto;
    padding: 1rem 0;
    em {
      font-weight: 700;
    }
    p, table {
      padding: 1.5rem 2rem;
      border-radius: .25rem;
      background-color: $searched-concept-example-bg;
      color: $searched-concept-example-text;
    }

    table {
      text-align: left;
      th, td {
        padding: 5px 10px;
      }
    }
  }
}

</style>
