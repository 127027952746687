<template>
  <nav class="c-nav-mobile">
    <ul class="c-nav-mobile__menu">
      <li v-if="submenu" class="c-nav-mobile__menu-item c-nav-mobile__menu-item--back">
        <a href="#" class="c-nav-mobile__menu-back" aria-label="tilbake til menyen" @click.prevent="backToMenu">
          <PrevIcon /><span class="c-nav-mobile__back-title">{{ submenu.title }}</span>
        </a>
      </li>
      <li class="c-nav-mobile__menu-item" v-for="item in currentMenu" :key="item.title">
        <NavMobileItem :item="item" @open-nested-nav="handleSubMenu" @close-menu="$emit('close-menu')" />
      </li>
    </ul>
  </nav>
</template>

<script>
import { ref } from '@vue/composition-api'
import NavMobileItem from '@/components/NavMobileItem'
import PrevIcon from '@/assets/svg/Prev'

export default {
  name: 'NavMobile',

  components: {
    NavMobileItem,
    PrevIcon
  },

  setup() {
    const menu = [
      {
        title: 'Guru',
        url: '/'
      },
      {
        title: 'Bøker',
        child: [
          {
            title: 'Håndbok',
            url: 'https://reader.dbok.no/#/book/5e81ae9634c6c80019166c49/1'
          },
          {
            title: 'Lesebok',
            url: 'https://reader.dbok.no/#/book/5e8450a234c6c8001916b3ae/1'
          },
          {
            title: 'Lesebok lydbok',
            url: '/lesebok-lydbok'
          }
        ]
      },
      {
        title: 'Skrivehjelp',
        url: '/skrivehjelp'
      },
      {
        title: 'Verktøy',
        url: '/verktoy'
      },
      {
        title: 'Lærer',
        url: 'https://www.synopsis.undervisning.fagbokforlaget.no/'
      }
    ]
    const submenu = ref(null)
    const currentMenu = ref(menu)
    const handleSubMenu = smenu => {
      currentMenu.value = smenu.child
      submenu.value = smenu
    }
    const backToMenu = () => {
      currentMenu.value = menu
      submenu.value = null
    }

    return {
      backToMenu,
      currentMenu,
      handleSubMenu,
      submenu
    }
  }
}
</script>

<style lang="scss">
.c-nav-mobile {
  position: relative;
  padding: 2rem;

  ul {
    margin: 0;
    padding: 0;
    text-align: left;
  }
  li {
    list-style-type: none;
  }

  @include bp(large) {
    display: none;
  }
}

.c-nav-mobile__menu-item {
  font-size: 1.3rem;
  margin-top: 1rem;

  a {
    text-decoration: none;
    color: $nav-text;
  }
}

.c-nav-mobile__menu-back {
  display: flex;
  align-items: center;
}

.c-nav-mobile__back-title {
  font-weight: 700;
  margin-left: 1rem;
}
</style>