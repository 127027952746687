<template>
  <div class="c-text-box">
    <div v-md-html="item.content" />
  </div>
</template>

<script>
export default {
  props: {
    item: Object
  }
}
</script>